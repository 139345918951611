import { ShipmentWithOrderAndTourDto } from '#shipment-service';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TourTypeIcon from '../customs/TourTypeIcon';
import { ShipmentType, TourType } from '../../shared/backend';

type Props = {
  shipment: ShipmentWithOrderAndTourDto;
};

const ShipmentHeader: React.FC<Props> = ({ shipment }) => {
  const { t } = useTranslation('customer');

  const displayedShipmentId = shipment.externalShipmentId || shipment.shipmentId;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <TourTypeIcon
        tourType={shipment.type === ShipmentType.SHIPMENT ? TourType.TOUR : TourType.RETOUR}
        sx={{ width: '1.5em', height: '1.5em' }}
      />
      <Typography variant="h5">{`${shipment.type === ShipmentType.SHIPMENT ? t('Shipment') : t('Return')} ${displayedShipmentId}`}</Typography>
    </Stack>
  );
};

export default ShipmentHeader;
