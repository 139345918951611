import { backendUri } from '../../shared/helper/env/helper';
import useSWR from 'swr';
import client, { IUseArrayResponse, IUseObjectResponse, SchemaType, api } from '../../shared/client';
import { Backend } from '../../shared/backend';
import { IActivityContext, activityContextToHeader } from '../activity-service/activity.service';
import { CreatedJobDataResponseDto } from '../customs-service/customs.service';

export interface OrderDto extends SchemaType<'OrderDto'> {}
export interface OrderWithCustomerDto extends SchemaType<'OrderWithCustomerDto'> {}
export interface OrderWithShipmentsAndToursDto extends SchemaType<'OrderWithShipmentsAndToursDto'> {}
export interface OrderUpdateDto extends SchemaType<'OrderUpdateDto'> {}

export namespace OrderService {
  export const useOrderById = (orderId: number | null): IUseObjectResponse<OrderWithCustomerDto> => {
    const url = orderId ? `${backendUri}/order/${orderId}` : null;
    const { data, error, mutate, isLoading } = useSWR<OrderWithCustomerDto>(url);

    return {
      data: data ?? null,
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const useOrderByShipmentId = (
    shipmentId: number | null,
  ): IUseObjectResponse<OrderWithShipmentsAndToursDto> => {
    const { data, error, mutate, isLoading } = api.useApi(
      shipmentId ? { key: '/order/by-shipment-id/{shipmentId}', shipmentId } : null,
    );

    return {
      data: data || null,
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const getOrderById = async (id: number): Promise<OrderDto> => {
    const res = await client.get<OrderDto>(`${backendUri}/order/${id}`);
    return res.data;
  };

  export const useOrders = (
    query: Backend.paths['/order/search']['get']['parameters']['query'],
    allowEmptySearch: boolean = false,
    disabled: boolean = false,
  ): IUseArrayResponse<OrderWithCustomerDto> => {
    const shouldFetch: boolean = allowEmptySearch || !!query?.search;
    const { data, mutate, error, isLoading } = api.useApi(shouldFetch && !disabled ? '/order/search' : null, {
      ...query,
      orderStates: query?.orderStates?.length ? query.orderStates : undefined,
    });

    return {
      data: data ?? [],
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const useTrackingOrders = (
    query?: Backend.paths['/order/tracking/search']['get']['parameters']['query'],
  ): IUseArrayResponse<OrderWithCustomerDto> => {
    const { data, mutate, error, isLoading } = api.useApi(query ? '/order/tracking/search' : null, query);

    return {
      data: data ?? [],
      mutate,
      error,
      isLoading,
      isError: !!error,
    };
  };

  export const putOrder = async (body: OrderUpdateDto, orderId: number, activityContext: IActivityContext = {}) => {
    const res = await api.put<'/order/{orderId}'>(`${backendUri}/order/${orderId}`, body, {
      headers: activityContextToHeader(activityContext),
    });

    return res.data;
  };

  export const createOrderInvoiceJob = async (
    orderId: number,
    alwaysUseArticlePrice: boolean = false,
  ): Promise<CreatedJobDataResponseDto> => {
    const res = await api.post<'/order/{orderId}/create-invoice'>(
      `${backendUri}/order/${orderId}/create-invoice?alwaysUseArticlePrice=${alwaysUseArticlePrice}`,
      undefined,
    );

    return res.data;
  };
}
