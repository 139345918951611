import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerDto, CustomerService, CustomerWithProcessesDto } from '#customer-service';
import { useAuthentication } from '../../hooks/useAuthentication';
import SelectWithFilter from '../../shared/components/SelectWithFilter';

export interface TrackingSearchValues {
  trackingNumber: string;
  orderNumber: string;
  name: string;
  customerIds: number[];
}

type Props = {
  trackingNumber: string;
  orderNumber: string;
  name: string;
  customerIds: number[];
  onSubmit: (values: TrackingSearchValues) => void;
  isLoading: boolean;
};

function compareCustomersAlphabetically(a: CustomerDto, b: CustomerDto): number {
  return a.company.localeCompare(b.company);
}

function generateLabelForCustomer(customer: CustomerWithProcessesDto): string {
  const processInfo = customer.processes.length
    ? `(${customer.processes.map((process) => process.processId).join(', ')})`
    : '';
  return `${customer.company} ${processInfo}`;
}

const TrackingSearch: React.FC<Props> = (props) => {
  const { t } = useTranslation('customer');
  const auth = useAuthentication();

  const customers = CustomerService.useCustomers();

  const [trackingNumber, setTrackingNumber] = useState(props.trackingNumber);
  const [orderNumber, setOrderNumber] = useState(props.orderNumber);
  const [name, setName] = useState(props.name);
  const [customerIds, setCustomerIds] = useState(props.customerIds);

  useEffect(() => {
    setTrackingNumber(props.trackingNumber);
  }, [props.trackingNumber]);

  useEffect(() => {
    setOrderNumber(props.orderNumber);
  }, [props.orderNumber]);

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  useEffect(() => {
    setCustomerIds(props.customerIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.customerIds.join(',')]);

  return (
    <Box>
      <Typography
        variant="body2"
        mb={2}
      >
        {t('Your search options. Select at least one.')}
      </Typography>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          props.onSubmit({ trackingNumber, orderNumber, name, customerIds });
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems="center"
        >
          <TextField
            size="small"
            margin="none"
            value={trackingNumber}
            onChange={(ev) => setTrackingNumber(ev.target.value)}
            label={t('Tracking ID')}
            variant="outlined"
            autoComplete="off"
          />

          <TextField
            size="small"
            margin="none"
            value={orderNumber}
            onChange={(ev) => setOrderNumber(ev.target.value)}
            label={t('Order ID')}
            variant="outlined"
            autoComplete="off"
          />

          <TextField
            size="small"
            margin="none"
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            label={t('Name')}
            variant="outlined"
            autoComplete="off"
          />

          {auth.isStaff() && customers.data && (
            <SelectWithFilter
              values={customers.data
                .sort(compareCustomersAlphabetically)
                .map((customer) => ({ label: generateLabelForCustomer(customer), id: customer.customerId }))}
              selectedValueIds={customerIds}
              onChange={(ids) => setCustomerIds(ids)}
              multiple={true}
              label={t('Customers')}
              size="small"
              sx={{
                minWidth: 140,
              }}
            />
          )}

          <Button
            startIcon={props.isLoading ? <CircularProgress size="1em" /> : <SearchIcon />}
            variant="contained"
            color="secondary"
            type={'submit'}
            disabled={
              (!name &&
                !orderNumber &&
                !trackingNumber &&
                (auth.isCustomer() || (auth.isStaff() && !customerIds.length))) ||
              props.isLoading
            }
          >
            {t('Search')}
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default TrackingSearch;
