import { ShipmentWithOrderAndProcessDto } from '#shipment-service';
import { Box, Button, Paper, TablePagination } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistoryNavigation } from '../../hooks/useHistoryNavigation';
import { useIntParam } from '../../hooks/useParam';
import DataTable, { TDataTableColumn } from '../../shared/components/data-table/DataTable';
import { ShipmentStateIcon } from '../../shared/components/shipment-state-icon/ShipmentStateIcon';

type Props = {
  shipments: ShipmentWithOrderAndProcessDto[];
  hideCompany: boolean;
  isLoading: boolean;
};

const ShipmentList: React.FC<Props> = ({ shipments, hideCompany, isLoading }) => {
  const { t } = useTranslation('customer');
  const { navigateTo } = useHistoryNavigation();

  const [page, setPage] = useIntParam('page', 0);
  const [rowsPerPage, setRowsPerPage] = useIntParam('pageSize', 10);

  const columns: TDataTableColumn<ShipmentWithOrderAndProcessDto>[] = [
    {
      title: t('Status'),
      render: (shipment) => (
        <Box
          component="span"
          sx={{ '>*': { verticalAlign: 'middle' } }}
        >
          {<ShipmentStateIcon state={shipment.state}></ShipmentStateIcon>}
        </Box>
      ),
    },
    { title: t('Online store'), render: (shipment) => shipment.process.customer.company, hidden: hideCompany },
    {
      title: t('Shipment ID'),
      render: (shipment) => (
        <Button
          onClick={() => navigateTo(`/customer/shipment/${shipment.shipmentId}`)}
          variant="outlined"
          size="small"
        >
          {shipment.externalShipmentId}
        </Button>
      ),
    },
    {
      title: t('Tracking id'),
      render: (shipment) => shipment.foreignInboundTrackingId ?? shipment.foreignOutboundTrackingId,
    },
    {
      title: t('Created on'),
      render: (shipment) => new Date(shipment.externalCreatedAt ?? shipment.createdAt).toLocaleDateString(),
    },
    {
      title: t('Customer name'),
      render: (shipment) => shipment.order.name,
    },
    { title: t('City'), render: (shipment) => shipment.order.city },
  ];

  return (
    <Paper sx={{ padding: 1 }}>
      <DataTable
        columns={columns}
        page={page}
        rows={shipments}
        isLoading={isLoading}
      />

      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
        page={page}
        count={shipments.length < rowsPerPage ? page * rowsPerPage + shipments.length : -1}
        onPageChange={(ev, page) => setPage(page)}
        showLastButton={true}
        showFirstButton={true}
      />
    </Paper>
  );
};

export default ShipmentList;
