import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { ShipmentWithTourDto } from '#shipment-service';
import { OrderWithCustomerDto } from '#order-service';
import { getTrackingState } from './TrackingFlowChart';
import LoupeIcon from '@mui/icons-material/Loupe';
import { ShipmentType } from '../../shared/backend';
import { getTrackingUrl } from '../orders/shared/components/order-detail-modal/components/shipment-view/components/TrackingInformation';
import TrackingStateText from './TrackingStateText';

type Props = {
  shipment: ShipmentWithTourDto;
  order: OrderWithCustomerDto;
};

export function getDisplayedTrackingId(shipment: ShipmentWithTourDto): string | null {
  return (
    shipment.foreignInboundTrackingId ??
    shipment.foreignOutboundTrackingId ??
    shipment.domesticInboundTrackingId ??
    shipment.domesticOutboundTrackingId
  );
}

const OrderShipment: React.FC<Props> = ({ shipment, order }) => {
  const { t } = useTranslation('customer');

  const trackingId = getDisplayedTrackingId(shipment);
  const { trackingState, timestamp, icon } = getTrackingState(shipment);
  const trackingUrl = trackingId ? getTrackingUrl(trackingId) : null;

  const localTimestamp = timestamp ? new Date(timestamp).toLocaleString() : undefined;

  return (
    <Card sx={{ boxShadow: 'none', border: '1px solid', borderColor: 'divider' }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              // width: '1.5em',
              // height: '1.5em',
              bgcolor: shipment.type === ShipmentType.SHIPMENT ? 'outbound.main' : 'inbound.main',
            }}
          >
            {icon}
          </Avatar>
        }
        title={shipment.externalShipmentId || shipment.shipmentId}
        subheader={<TrackingStateText state={trackingState} />}
      />

      <CardContent>
        {shipment.lineItems.length > 0 && (
          <Typography gutterBottom>
            {t('The package contains {{count}} line items.', { count: shipment.lineItems.length })}
          </Typography>
        )}
        {shipment.lineItems.length === 0 && (
          <Typography gutterBottom>{t('The package does not contain any items')}</Typography>
        )}

        {trackingUrl && (
          <Typography>
            <Trans
              t={t}
              key="Shipment was carried with tracking id"
              trackingUrl={trackingUrl}
            >
              Shipment was carried with tracking id{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={trackingUrl}
              >
                {{ trackingId } as any}
              </Link>
              .
            </Trans>
          </Typography>
        )}
      </CardContent>

      <CardActions sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
        {localTimestamp && (
          <Typography sx={{ color: 'text.disabled' }}>
            {t('Last update: {{localTimestamp}}', { localTimestamp, interpolation: { escapeValue: false } })}
          </Typography>
        )}

        <Button
          component={Link}
          to={`/customer/shipment/${shipment.shipmentId}`}
          variant="contained"
          startIcon={<LoupeIcon />}
        >
          {t('View details')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default OrderShipment;
