import { CircularProgress, Grid, Paper, Stack } from '@mui/material';
import React from 'react';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TrackingSideBar from './TrackingSideBar';
import OrderHeader from './OrderHeader';
import OrderShipments from './OrderShipments';
import { useParams } from 'react-router-dom';
import { OrderService } from '#order-service';
import ErrorPage from '../../shared/components/ErrorPage';
import { useTranslation } from 'react-i18next';
import OrderBreadCrumbs from './OrderBreadCrumbs';
import ButtonAsync from '../../shared/components/ButtonAsync';
import { ShipmentService } from '#shipment-service';
import FileSaver from 'file-saver';
import { useNotifications } from '../../hooks/useNotifications';
import { OrderType } from '../../shared/backend';
import { getJobResultFileName, jobService } from '../../services/job-service/job.service';
import { pdfFileDownload } from '../../shared/helper/pdfFileDownload';
import { useAuthentication } from '../../hooks/useAuthentication';

const OrderDetails: React.FC = () => {
  const { t } = useTranslation('customer');
  const notifications = useNotifications();
  const auth = useAuthentication();

  const { orderId } = useParams();
  const parsedOrderId = parseInt(orderId ?? '', 10);

  const order = OrderService.useOrderById(!isNaN(parsedOrderId) ? parsedOrderId : null);
  const shipments = ShipmentService.useShipmentsByOrder(order.data?.orderId, OrderType.ORDER);
  const returnShipments = ShipmentService.useShipmentsByOrder(order.data?.orderId, OrderType.RETOURE);

  const hasTourAssigned = shipments.data.some((shipment) => shipment.tourId);
  const isInvoiceAvailable = ['CH', 'GB', 'DE', 'NO'].includes(
    order.data?.process.processType.destinationCountry ?? '',
  );

  const printReturnLabel = async () => {
    try {
      // Create an empty return shipment for the order and return the label
      const returnShipment = await ShipmentService.createReturnShipmentWithLabel(parsedOrderId);
      const filename = `return_label_${returnShipment.shipmentId}.pdf`;
      FileSaver.saveAs(`data:application/pdf;base64,${returnShipment.label}`, filename);

      await returnShipments.mutate();
    } catch (error) {
      notifications.addError(error);
    }
  };

  const handleCreateOrderInvoice = async (alwaysUseArticlePrice: boolean = false) => {
    if (!order.data?.orderId) {
      return;
    }

    try {
      const jobData = await OrderService.createOrderInvoiceJob(order.data.orderId, alwaysUseArticlePrice);

      const job = await jobService.addJobPromise(jobData.jobId);

      const pdfBuffer = job.returnvalue.file;
      if (pdfBuffer) {
        notifications.addInfo(
          t(`The invoice for order #{{orderId}} has been successfully created and is being downloaded.`, {
            orderId: order.data.orderId,
          }),
        );
        pdfFileDownload(pdfBuffer, `${alwaysUseArticlePrice ? 'articles_' : ''}${getJobResultFileName(job)}`);
      } else {
        const { jobId } = jobData;
        notifications.addError(t(`Document from job {{jobId}} could not be downloaded`, { jobId }));
      }
    } catch (error) {
      notifications.addError(error);
    }
  };

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        {order.data && <OrderBreadCrumbs order={order.data}></OrderBreadCrumbs>}
      </Grid>

      <Grid
        item
        xs={12}
        lg={10}
      >
        <Paper sx={{ padding: 2 }}>
          {order.data && (
            <>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  marginBottom={6}
                >
                  <OrderHeader order={order.data} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={8}
                  paddingRight={3}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    {isInvoiceAvailable && auth.isOffice() && (
                      <>
                        <ButtonAsync
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => handleCreateOrderInvoice()}
                          startIcon={<ReceiptIcon />}
                          disabled={!hasTourAssigned}
                        >
                          {t('Invoice')}
                        </ButtonAsync>

                        {order.data.process.alwaysUseArticlePriceForDeCustoms && (
                          <ButtonAsync
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleCreateOrderInvoice(true)}
                            startIcon={<ReceiptIcon />}
                            disabled={!hasTourAssigned}
                          >
                            {t('Invoice (with article price)')}
                          </ButtonAsync>
                        )}
                      </>
                    )}

                    <ButtonAsync
                      size="small"
                      variant="outlined"
                      startIcon={<PrintIcon />}
                      onClick={() => printReturnLabel()}
                    >
                      {t('Create new return label')}
                    </ButtonAsync>
                  </Stack>

                  <OrderShipments order={order.data} />
                </Grid>

                <Grid
                  sx={{ borderLeft: '1px solid', borderColor: 'divider' }}
                  item
                  xs={12}
                  md={4}
                >
                  <TrackingSideBar order={order.data}></TrackingSideBar>
                </Grid>
              </Grid>
            </>
          )}

          {order.isLoading && <CircularProgress />}

          {!order.isLoading && !order.data && (
            <ErrorPage
              title={t('Order not found')}
              content={t('But at least you can look at a sweet kitten.')}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
