import { Grid } from '@mui/material';
import React from 'react';
import BarChartShipmentType from './charts/BarChartShipmentType';
import DashboardHeader from './DashboardHeader';
import { useCountryParam, useCustomerIdParam, useFromParam, useToParam } from './dashboard.params';
import ReturnRate from './charts/ReturnRate';
import BarChartWeightCategory from './charts/BarChartWeightCategory';
import { IsoCountryCode } from '../../shared/backend';
import TicketCount from './charts/TicketCount';
import LineChartCarrierTime from './charts/LineChartCarrierTime';

type Props = {};

const DashboardGrid: React.FC<Props> = () => {
  const [from] = useFromParam();
  const [to] = useToParam();
  const [customerId] = useCustomerIdParam();
  const [destinationCountry] = useCountryParam();

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <DashboardHeader />
      </Grid>

      {!!(customerId && destinationCountry && from && to) && (
        <>
          <Grid
            item
            xs={12}
            md={6}
          >
            <BarChartShipmentType
              customerId={customerId}
              destinationCountry={destinationCountry}
              from={from}
              to={to}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <LineChartCarrierTime
              customerId={customerId}
              destinationCountry={destinationCountry}
              from={from}
              to={to}
            />
          </Grid>

          {destinationCountry === IsoCountryCode.CH && (
            <Grid
              item
              xs={12}
              md={6}
            >
              <BarChartWeightCategory
                customerId={customerId}
                destinationCountry={destinationCountry}
                from={from}
                to={to}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
          >
            <ReturnRate
              customerId={customerId}
              destinationCountry={destinationCountry}
              from={from}
              to={to}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
          >
            <TicketCount customerId={customerId} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DashboardGrid;
