import { DateTime } from 'luxon';
import { Backend } from '../../shared/backend';
import { IUseArrayResponse, IUseObjectResponse, SchemaType, useApi } from '../../shared/client';

export interface BigQueryProcessedShipment extends SchemaType<'BigQueryProcessedShipment'> {}
export interface BigQueryWeightCategory extends SchemaType<'BigQueryWeightCategory'> {}
export interface BigQueryCarrierTime extends SchemaType<'BigQueryCarrierTime'> {}
export interface BigQueryTicketCount extends SchemaType<'BigQueryTicketCount'> {}

type ProcessedShipmentParams =
  Backend.operations['DashboardController_getProcessedShipmentsByType']['parameters']['path'] & {
    from: DateTime;
    to: DateTime;
  };

type WeightCategoryParams = Backend.operations['DashboardController_getWeightCategories']['parameters']['path'] & {
  from: DateTime;
  to: DateTime;
};

type CarrierTimeParams = Backend.operations['DashboardController_getCarrierTime']['parameters']['path'] & {
  from: DateTime;
  to: DateTime;
};

type TicketCountParams = Backend.operations['DashboardController_getTicketCount']['parameters']['path'];

export namespace DashboardService {
  export const useProcessedShipments = (
    props: ProcessedShipmentParams | null,
  ): IUseArrayResponse<BigQueryProcessedShipment> & { isValidating: boolean } => {
    const { data, error, mutate, isLoading, isValidating } = useApi(
      props
        ? {
            key: '/dashboard/{customerId}/processed-shipments/{destinationCountry}',
            customerId: props.customerId,
            destinationCountry: props.destinationCountry,
          }
        : null,
      { from: props?.from.toISODate() ?? '', to: props?.to.toISODate() ?? '' },
    );

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const useWeightCategories = (
    props: WeightCategoryParams | null,
  ): IUseArrayResponse<BigQueryWeightCategory> & { isValidating: boolean } => {
    const { data, error, mutate, isLoading, isValidating } = useApi(
      props
        ? {
            key: '/dashboard/{customerId}/weight-category/{destinationCountry}',
            customerId: props.customerId,
            destinationCountry: props.destinationCountry,
          }
        : null,
      { from: props?.from.toISODate() ?? '', to: props?.to.toISODate() ?? '' },
    );

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const useCarrierTime = (
    props: CarrierTimeParams | null,
  ): IUseArrayResponse<BigQueryCarrierTime> & { isValidating: boolean } => {
    const { data, error, mutate, isLoading, isValidating } = useApi(
      props
        ? {
            key: '/dashboard/{customerId}/carrier-time/{destinationCountry}',
            customerId: props.customerId,
            destinationCountry: props.destinationCountry,
          }
        : null,
      { from: props?.from.toISODate() ?? '', to: props?.to.toISODate() ?? '' },
    );

    return {
      data: data ?? [],
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  export const useTicketCount = (
    props: TicketCountParams | null,
  ): IUseObjectResponse<BigQueryTicketCount> & { isValidating: boolean } => {
    const { data, error, mutate, isLoading, isValidating } = useApi(
      props
        ? {
            key: '/dashboard/{customerId}/ticket-count',
            customerId: props.customerId,
          }
        : null,
    );

    return {
      data: data ?? null,
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };
}
