import { Box, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabContext, TabPanel } from '@mui/lab';
import LineItemTable from './LineItemTable';
import OrderShipment from './OrderShipment';
import { ShipmentService, ShipmentWithTourDto } from '#shipment-service';
import { OrderType, TourType } from '../../shared/backend';
import { OrderWithCustomerDto } from '#order-service';
import TourTypeIcon from '../customs/TourTypeIcon';
import { IUseArrayResponse } from '../../shared/client';

type Props = {
  order: OrderWithCustomerDto;
};

enum OrderShipmentsTab {
  SHIPMENTS = '1',
  RETURNS = '2',
}

const ShipmentTabPanel = (
  value: string,
  shipments: IUseArrayResponse<ShipmentWithTourDto>,
  unassignedLineItems: OrderWithCustomerDto['lineItems'],
  order: OrderWithCustomerDto,
) => {
  const { t } = useTranslation('customer');

  return (
    <TabPanel
      value={value}
      sx={{ px: 0 }}
    >
      {shipments.isLoading &&
        [0, 1, 2].map((key) => (
          <Skeleton
            key={key}
            variant="rectangular"
            height={55}
            sx={{ m: 1 }}
          ></Skeleton>
        ))}

      {!shipments.isLoading && (
        <Stack spacing={8}>
          {!!shipments.data.length && (
            <Stack spacing={2}>
              {shipments.data.map((shipment) => (
                <OrderShipment
                  key={shipment.shipmentId}
                  shipment={shipment}
                  order={order}
                ></OrderShipment>
              ))}
            </Stack>
          )}
          {!shipments.data.length && (
            <Box sx={{ paddingX: 1, paddingY: 2 }}>
              <Typography
                variant="body2"
                align="center"
                color="text.disabled"
              >
                {t('No shipments available')}
              </Typography>
            </Box>
          )}

          {unassignedLineItems.length > 0 && (
            <LineItemTable
              lineItems={unassignedLineItems}
              heading={t('Unassigned line items')}
              subheading={t('Those line items do not belong to any shipment')}
              currency={order.currency}
            ></LineItemTable>
          )}
        </Stack>
      )}
    </TabPanel>
  );
};

const OrderShipments: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation('customer');

  const regularShipments = ShipmentService.useShipmentsByOrder(order.orderId, OrderType.ORDER);
  const returnShipments = ShipmentService.useShipmentsByOrder(order.orderId, OrderType.RETOURE);

  const unassignedShipmentLines = order.lineItems.filter((lineItem) => !lineItem.shipmentId);
  const unassignedReturnLines = order.lineItems.filter((lineItem) => !lineItem.returnShipmentId);

  const [selectedTab, setSelectedTab] = React.useState(OrderShipmentsTab.SHIPMENTS);

  return (
    <Box sx={{ padding: 1 }}>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={(event, value) => setSelectedTab(value)}
          >
            <Tab
              icon={
                <TourTypeIcon
                  tourType={TourType.TOUR}
                  sx={{ width: '1.5em', height: '1.5em', mr: 1 }}
                />
              }
              iconPosition="start"
              label={`${t('Outbound')} (${regularShipments.isLoading ? '...' : regularShipments.data.length})`}
              value={OrderShipmentsTab.SHIPMENTS}
              sx={{ mr: 2 }}
            />

            <Tab
              icon={
                <TourTypeIcon
                  tourType={TourType.RETOUR}
                  sx={{ width: '1.5em', height: '1.5em', mr: 1 }}
                />
              }
              iconPosition="start"
              label={`${t('Inbound')} (${returnShipments.isLoading ? '...' : returnShipments.data.length})`}
              value={OrderShipmentsTab.RETURNS}
            />
          </Tabs>
        </Box>

        {ShipmentTabPanel(OrderShipmentsTab.SHIPMENTS, regularShipments, unassignedShipmentLines, order)}
        {ShipmentTabPanel(OrderShipmentsTab.RETURNS, returnShipments, unassignedReturnLines, order)}
      </TabContext>
    </Box>
  );
};

export default OrderShipments;
