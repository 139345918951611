import React, { useEffect, useState } from 'react';
import { useEnumParam } from '../../hooks/useParam';
import { ProcessTypeService } from '../../services/process-type-service/process-type.service';
import { ProcessService } from '../../services/process-service/process.service';
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SelectWithFilter from '../../shared/components/SelectWithFilter';
import { DateTime } from 'luxon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Download from '@mui/icons-material/Download';
import { ShipmentState, ShipmentType } from '../../shared/backend';
import { shipmentStateOptions } from '../../shared/components/shipment-filter/ShipmentFilter';
import { useTranslation } from 'react-i18next';
import ShipmentStateLabel from '../../shared/components/shipment-filter/ShipmentStateLabel';
import i18next from 'i18next';
import { useAuthentication } from '../../hooks/useAuthentication';
import { DocumentReportOptionsDto, useReportDocument } from '../../services/job-service/job.service';
import ButtonAsync from '../../shared/components/ButtonAsync';
import { round } from 'lodash';
import { Sync } from '@mui/icons-material';

enum Direction {
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
}

type Props = {};

const ReportsPage: React.FC<Props> = () => {
  const auth = useAuthentication();
  const { t } = useTranslation('reports');
  const processTypes = ProcessTypeService.useProcessTypes();
  const processes = ProcessService.useProcesses();

  const [direction, setDirection] = useEnumParam('direction', Direction, Direction.OUTBOUND);
  const [processTypeIds, setProcessTypeIds] = useState<number[]>([]);
  const [processIds, setProcessIds] = useState<number[]>([]);
  const [shipmentStates, setShipmentStates] = useState<ShipmentState[]>([]);
  const [startDate, setStartDate] = useState<DateTime<true>>(DateTime.now().minus({ month: 1 }));
  const [endDate, setEndDate] = useState<DateTime<true>>(DateTime.now());
  const [showBlocked, setShowBlocked] = useState(false);
  const [generationRequested, setGenerationRequested] = useState(false);

  const filterProcessByProcessTypes = (ids: number[]) => {
    return processes.data.filter((process) => ids.includes(process.processTypeId) && process.blocked === showBlocked);
  };

  const availableProcesses = filterProcessByProcessTypes(processTypeIds);

  const isValidTimeRange = startDate <= endDate && endDate.diff(startDate).as('days') <= 31;

  const reportOptions: DocumentReportOptionsDto | null =
    processIds.length && direction && isValidTimeRange
      ? {
          processIds,
          fromDate: startDate.startOf('day').toISO(),
          toDate: endDate.endOf('day').toISO(),
          shipmentType: direction === Direction.OUTBOUND ? ShipmentType.SHIPMENT : ShipmentType.RETOUR,
          shipmentStates: shipmentStates.length ? shipmentStates : undefined,
        }
      : null;

  const report = useReportDocument(reportOptions);
  const isProcessing = report?.isActive || report?.isWaiting;
  const { downloadUrl } = report ?? {};

  useEffect(() => {
    if (generationRequested && downloadUrl) {
      window.open(downloadUrl, '_blank', 'noreferrer');

      setGenerationRequested(false);
    }
  }, [generationRequested, downloadUrl]);

  return (
    <Paper sx={{ px: 2, py: 3 }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="h5"
            gutterBottom
          >
            {t('Reports')}
          </Typography>
          <Typography
            variant="body1"
            mb={3}
          >
            {t('Here you can download information about outgoing (outbound) and incoming (inbound) shipments.', {
              ns: 'reports',
            })}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={3}
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{ borderTop: '1px solid', borderColor: 'divider', pt: 3 }}
          >
            <FormControl disabled={isProcessing}>
              <FormLabel id="direction">{t('Direction')}</FormLabel>
              <RadioGroup
                aria-labelledby="direction"
                value={direction ?? ''}
                onChange={(ev) => {
                  setDirection(ev.target.value as Direction);
                }}
              >
                <FormControlLabel
                  value={Direction.OUTBOUND}
                  label={t('Outbound')}
                  control={<Radio color="outbound" />}
                />
                <FormControlLabel
                  value={Direction.INBOUND}
                  label={t('Inbound (returns)')}
                  control={<Radio color="inbound" />}
                />
              </RadioGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Process groups')}</FormLabel>

              <RadioGroup
                aria-labelledby="direction"
                value={processTypeIds[0] ?? ''}
                onChange={(ev) => {
                  const newProcessTypeId = parseInt(ev.target.value, 10);
                  setProcessTypeIds([newProcessTypeId]);

                  const newAvailableProcesses = filterProcessByProcessTypes([newProcessTypeId]);

                  if (newAvailableProcesses.length === 1) {
                    setProcessIds(newAvailableProcesses.map(({ processId }) => processId));
                  } else {
                    setProcessIds([]);
                  }
                }}
              >
                {processTypes.data
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((processType) => {
                    return (
                      <FormControlLabel
                        key={processType.processTypeId}
                        value={processType.processTypeId}
                        label={processType.label}
                        control={<Radio />}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Process')}</FormLabel>

              <FormGroup>
                {processTypeIds.length > 0 ? (
                  <>
                    {auth.isStaff() && (
                      <TextField
                        select
                        value={showBlocked ? '1' : '0'}
                        onChange={(ev) => {
                          setProcessIds([]);
                          setShowBlocked(ev.target.value === '1');
                        }}
                        fullWidth
                        size="small"
                        sx={{ mt: 2, minWidth: 180 }}
                      >
                        <MenuItem value="0">{t('Only active')}</MenuItem>
                        <MenuItem value="1">{t('Only blocked')}</MenuItem>{' '}
                      </TextField>
                    )}
                    {availableProcesses.length > 0 ? (
                      <SelectWithFilter
                        values={availableProcesses.map((p) => ({
                          id: p.processId,
                          label: `${p.customer.company} (${p.processId})`,
                        }))}
                        selectedValueIds={processIds}
                        onChange={setProcessIds}
                        fullWidth
                        size="small"
                        sx={{ mt: 2, minWidth: 180 }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.disabled"
                        sx={{ mt: 1 }}
                      >
                        <em>{t('No processes available')}</em>
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>{t('No process group selected')}</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            <FormControl
              component="fieldset"
              disabled={isProcessing}
            >
              <FormLabel component="legend">{t('Time period')}</FormLabel>

              <FormGroup>
                {processIds.length > 0 ? (
                  <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={i18next.language}
                  >
                    <Stack
                      direction="column"
                      spacing={2}
                      sx={{ mt: 2 }}
                    >
                      <DatePicker
                        disabled={isProcessing}
                        label={t('From')}
                        value={startDate}
                        onChange={(value) => value && setStartDate(value)}
                        slotProps={{ textField: { variant: 'outlined', size: 'small', error: !isValidTimeRange } }}
                        disableFuture
                      />

                      <DatePicker
                        disabled={isProcessing}
                        label={t('Until')}
                        value={endDate}
                        onChange={(value) => value && setEndDate(value)}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            size: 'small',
                            error: !isValidTimeRange,
                            helperText: t('A maximum time period of 31 days is possible.'),
                          },
                        }}
                        disableFuture
                      />

                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setStartDate(DateTime.now().set({ day: 1 }));
                            setEndDate(DateTime.now());
                          }}
                        >
                          {t('This month')}
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setStartDate(DateTime.now().minus({ weeks: 4 }));
                            setEndDate(DateTime.now());
                          }}
                        >
                          {t('Last 4 weeks')}
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setStartDate(DateTime.now().set({ day: 1 }).minus({ month: 1 }));
                            setEndDate(DateTime.now().minus({ month: 1 }).endOf('month'));
                          }}
                        >
                          {t('Last month')}
                        </Button>
                      </Stack>
                    </Stack>
                  </LocalizationProvider>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ mt: 1 }}
                  >
                    <em>{t('No process selected')}</em>
                  </Typography>
                )}
              </FormGroup>
            </FormControl>

            {direction === Direction.INBOUND && (
              <FormControl
                component="fieldset"
                disabled={isProcessing}
              >
                <FormLabel component="legend">{t('State')}</FormLabel>
                <FormGroup>
                  {isValidTimeRange &&
                    processIds.length > 0 &&
                    shipmentStateOptions.map((state) => {
                      return (
                        <FormControlLabel
                          key={state}
                          label={<ShipmentStateLabel state={state} />}
                          control={
                            <Checkbox
                              color="primary"
                              checked={shipmentStates.includes(state)}
                              onChange={(ev) => {
                                const newShipmentStates = ev.target.checked
                                  ? [...shipmentStates, state]
                                  : shipmentStates.filter((s) => s !== state);

                                setShipmentStates(newShipmentStates);
                              }}
                            />
                          }
                        />
                      );
                    })}
                  {(!isValidTimeRange || processIds.length === 0) && (
                    <Typography
                      variant="body2"
                      color="text.disabled"
                      sx={{ mt: 1 }}
                    >
                      <em>{t('No time period selected')}</em>
                    </Typography>
                  )}
                </FormGroup>
              </FormControl>
            )}

            <Stack
              direction="column"
              spacing={2}
            >
              <FormControl>
                <FormLabel>{t('Actions')}</FormLabel>
                <FormGroup>
                  {report?.downloadUrl ? (
                    <Button
                      variant="contained"
                      color={direction === Direction.OUTBOUND ? 'outbound' : 'inbound'}
                      component={'a'}
                      href={report.downloadUrl}
                      target="_blank"
                      startIcon={<Download />}
                    >
                      {t('CSV report')}
                    </Button>
                  ) : (
                    <ButtonAsync
                      component={report?.downloadUrl ? 'a' : undefined}
                      href={report?.downloadUrl ?? undefined}
                      sx={{ mt: 1 }}
                      variant="contained"
                      disabled={
                        processIds.length === 0 ||
                        isProcessing ||
                        !isValidTimeRange ||
                        (direction === Direction.INBOUND && shipmentStates.length === 0)
                      }
                      color={direction === Direction.OUTBOUND ? 'outbound' : 'inbound'}
                      startIcon={isProcessing ? <CircularProgress size="1em" /> : <Sync />}
                      onClick={async () => {
                        setGenerationRequested(true);

                        await report?.create();
                      }}
                    >
                      {t('CSV report')}{' '}
                      {report?.isActive && typeof report?.data.progress === 'number'
                        ? `(${round(report.data.progress * 100, 1)}%)`
                        : ''}
                    </ButtonAsync>
                  )}
                </FormGroup>
              </FormControl>

              {report?.data?.failedReason && (
                <Alert severity="warning">
                  {t('Report generation failed with reason: {{reason}}', { reason: report.data.failedReason })}
                </Alert>
              )}
              {report?.isWaiting && <Alert severity="info">{t('Report generation is prepared.')}</Alert>}
              {report?.isActive && <Alert severity="info">{t('Report is being generated.')}</Alert>}
              {report?.isComplete && (
                <Alert severity="success">
                  {t('Report is ready for download.')}

                  {report.downloadUrl && (
                    <Typography>
                      <a
                        href={report.downloadUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {report.data.filename || t('Report')}
                      </a>
                    </Typography>
                  )}
                </Alert>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReportsPage;
