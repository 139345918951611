import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  TimeScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Paper, Skeleton, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { DashboardService } from '../../../services/dashboard-service/dashboard.service';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors, TimeScale);

type Props = {
  customerId: number;
  destinationCountry: string;
  from: DateTime;
  to: DateTime;
};

const BarChartWeightCategory: React.FC<Props> = ({ customerId, destinationCountry, from, to }) => {
  const { t } = useTranslation('reports');

  const weightCategories = DashboardService.useWeightCategories({ customerId, destinationCountry, from, to });
  const data = weightCategories.data.map((item) => ({ ...item, date: new Date(item.date) }));

  const categories = Object.groupBy(data, ({ category }) => category);
  const colors = ['#004c6d', '#346888', '#5886a5', '#7aa6c2', '#9dc6e0', '#c1e7ff'].reverse();
  // ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'].reverse();

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6">{t('Parcels by weight category')}</Typography>

      {weightCategories.isLoading && (
        <Skeleton
          variant="rectangular"
          height={300}
        />
      )}
      {weightCategories.data.length === 0 && !weightCategories.isLoading && !weightCategories.isError && (
        <Typography sx={{ textAlign: 'center', color: 'text.disabled' }}>{t('No data available')}</Typography>
      )}
      {weightCategories.isError && (
        <Alert severity="error">
          <AlertTitle>{t('Error while loading data')}</AlertTitle>

          {JSON.stringify(weightCategories.error, undefined, 2)}
        </Alert>
      )}
      {weightCategories.data.length > 0 && (
        <Bar
          options={{
            indexAxis: 'x' as const,
            scales: {
              x: {
                stacked: true,
                type: 'time',
                time: {
                  round: 'day',
                  minUnit: 'day',
                },
              },
              y: {
                stacked: true,
              },
            },
            responsive: true,
            plugins: {
              title: {
                display: false,
                text: t('Parcels by type'),
              },
              legend: {
                labels: {
                  usePointStyle: true,
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) =>
                    DateTime.fromMillis(context[0].parsed.x).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
                },
              },
            },
          }}
          data={{
            datasets: Object.entries(categories)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([label, items], index) => ({
                label,
                data: (items ?? [])
                  .sort((a, b) => a.date.getTime() - b.date.getTime())
                  .map(({ date, count }) => ({ x: date.getTime(), y: count })),
                backgroundColor: colors[index],
              })),
          }}
        />
      )}
    </Paper>
  );
};

export default BarChartWeightCategory;
