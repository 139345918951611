import useSWR from 'swr';
import client from '../../shared/client';
import { backendUri } from '../../shared/helper/env/helper';
import { SchemaObject } from 'openapi3-ts/oas31';

export const importArticles = async (processId: number) => {
  const res = await client.get(`${backendUri}/v1/task/${processId}/importArticles`);

  return res.data;
};

export const importOrders = async (processId: number) => {
  const res = await client.get(`${backendUri}/v1/task/${processId}/importOrders`);

  return res.data;
};

export const useShopSystems = () => {
  const url = `${backendUri}/importer/shopSystems`;
  const { data, error, mutate } = useSWR<string[]>(url);

  return {
    data: data || [],
    error,
    isLoading: !error && typeof data === 'undefined',
    isError: !!error,
    mutate,
  };
};

export const useShopSystemCredentialSchema = (shopSystem: string | null) => {
  const url = shopSystem ? `${backendUri}/importer/shopSystem/${shopSystem}/credential` : null;
  const { data, error, mutate } = useSWR<SchemaObject | null>(url);

  return {
    data,
    error,
    isLoading: !!url && !error && typeof data === 'undefined',
    isError: !!error,
    mutate,
  };
};
