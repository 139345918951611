import React from 'react';
import { DisplayedTrackingState } from './TrackingFlowChart';
import { useTranslation } from 'react-i18next';

type Props = {
  state: DisplayedTrackingState;
};

const TrackingStateText: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation('customer');

  const translations: Record<DisplayedTrackingState, string> = {
    [DisplayedTrackingState.DELIVERED]: t('Delivered'),
    [DisplayedTrackingState.IN_DEST_COUNTRY]: t('In destination country'),
    [DisplayedTrackingState.CUSTOMS_CLEARED]: t('Customs cleared'),
    [DisplayedTrackingState.IN_CUSTOMS_CLEARANCE]: t('In customs clearance'),
    [DisplayedTrackingState.PROCESSED]: t('Processed'),
    [DisplayedTrackingState.RECORD_IN_SYSTEM]: t('Record in system'),
    [DisplayedTrackingState.ANNOUNCED]: t('Announced'),
  };

  return translations[state];
};

export default TrackingStateText;
