import { Alert, AlertTitle, Box, Paper, Skeleton, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { useTranslation } from 'react-i18next';
import { BigQueryProcessedShipment, DashboardService } from '../../../services/dashboard-service/dashboard.service';
import _ from 'lodash';

function getCountByType(data: BigQueryProcessedShipment[]) {
  return data.reduce((prev, { type, count }) => ({ ...prev, [type]: prev[type] + count }), {
    shipment: 0,
    retour: 0,
  });
}

type Props = {
  customerId: number;
  destinationCountry: string;
  from: DateTime;
  to: DateTime;
};

const ReturnRate: React.FC<Props> = ({ customerId, destinationCountry, from, to }) => {
  const { t } = useTranslation('reports');

  const processedShipments = DashboardService.useProcessedShipments({ customerId, destinationCountry, from, to });
  const referenceProcessedShipments = DashboardService.useProcessedShipments({
    customerId,
    destinationCountry,
    from: from.minus({ year: 1 }),
    to: to.minus({ year: 1 }),
  });

  const countByType = getCountByType(processedShipments.data);
  const referenceCountByType = getCountByType(referenceProcessedShipments.data);

  const returnRate = countByType.shipment ? _.round((countByType.retour / countByType.shipment) * 100, 2) : 0;
  const referenceReturnRate = referenceCountByType.shipment
    ? _.round((referenceCountByType.retour / referenceCountByType.shipment) * 100, 2)
    : 0;

  const diff = _.round(returnRate - referenceReturnRate, 1);

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6">{t('Return rate (vs. 1 year ago)')}</Typography>

      {processedShipments.isLoading && (
        <Skeleton
          variant="rectangular"
          height={300}
        />
      )}
      {processedShipments.data.length === 0 && !processedShipments.isLoading && !processedShipments.isError && (
        <Typography sx={{ textAlign: 'center', color: 'text.disabled' }}>{t('No data available')}</Typography>
      )}
      {processedShipments.isError && (
        <Alert severity="error">
          <AlertTitle>{t('Error while loading data')}</AlertTitle>

          {JSON.stringify(processedShipments.error, undefined, 2)}
        </Alert>
      )}

      {!processedShipments.isLoading && (
        <Box
          paddingTop={6}
          paddingBottom={3}
          sx={{ fontSize: '1.5rem' }}
        >
          {!referenceProcessedShipments.isLoading && (
            <>
              <Typography
                sx={{ textAlign: 'center', fontSize: '1.2em' }}
                color="text.disabled"
              >
                {referenceReturnRate}%
              </Typography>

              <Typography
                sx={{ textAlign: 'center', fontSize: '0.8em' }}
                color={diff < 0 ? 'error' : diff > 0 ? 'success' : undefined}
              >
                {diff}%{' '}
                {diff < 0 && <ArrowCircleDownIcon sx={{ verticalAlign: 'text-top', width: '1em', height: '1em' }} />}
                {diff > 0 && <ArrowCircleUpIcon sx={{ verticalAlign: 'text-top', width: '1em', height: '1em' }} />}
              </Typography>
            </>
          )}

          <Typography sx={{ textAlign: 'center', fontSize: '1.8em' }}>{returnRate}%</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default ReturnRate;
