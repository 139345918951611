import useSWR from 'swr';
import { SchemaType, api } from '../../shared/client';
import { backendUri } from '../../shared/helper/env/helper';
import { SchemaObject } from 'openapi3-ts/oas31';

export interface CarrierOptionDto extends SchemaType<'CarrierOptionDto'> {}
export interface CreateCarrierOptionDto extends SchemaType<'CreateCarrierOptionDto'> {}

export const postCarrierOption = async (body: CreateCarrierOptionDto, processId: number, shipmentMethodId: number) => {
  const res = await api.post<'/v1/process/{processId}/shipmentMethod/{shipmentMethodId}/carrierOption'>(
    `/v1/process/${processId}/shipmentMethod/${shipmentMethodId}/carrierOption`,
    body,
  );

  return res.data;
};

export const deleteCarrierOption = async (processId: number, shipmentMethodId: number, carrierOptionId: number) => {
  await api.delete<'/v1/process/{processId}/shipmentMethod/{shipmentMethodId}/carrierOption/{carrierOptionId}'>(
    `${backendUri}/v1/process/${processId}/shipmentMethod/${shipmentMethodId}/carrierOption/${carrierOptionId}`,
  );
};

export const useCarrierOptionsByProcessAndShipmentMethod = (processId: number, shipmentMethodId: number) => {
  const url = `${backendUri}/v1/process/${processId}/shipmentMethod/${shipmentMethodId}/carrierOptions`;
  const { data, error, mutate, isLoading } = useSWR<CarrierOptionDto[]>(url, { shouldRetryOnError: false });

  return {
    data,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

export const useCarrierOptionsByCarrierName = (carrierName: string) => {
  const url = `${backendUri}/carrier/${carrierName}/options`;
  const { data, error, mutate, isLoading } = useSWR<string[]>(carrierName ? url : null, { shouldRetryOnError: false });

  return {
    data,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};

export const useCarrierCredentialSchemaByCarrierName = (carrierName: string) => {
  const url = `${backendUri}/carrier/${carrierName}/credential`;
  const { data, isLoading, error, mutate } = useSWR<SchemaObject | ''>(url, { shouldRetryOnError: false });

  return {
    data: data === '' ? null : data,
    error,
    isLoading,
    isError: !!error,
    mutate,
  };
};
