import { OrderWithCustomerDto } from '#order-service';
import { Box, Button, Paper, TablePagination } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntParam } from '../../hooks/useParam';
import { useHistoryNavigation } from '../../hooks/useHistoryNavigation';
import DataTable, { TDataTableColumn } from '../../shared/components/data-table/DataTable';
import { OrderStateIcon } from '../orders/all-orders/components/OrderStateIcons';
import { countryToFlag } from '../../shared/helper/country';

type Props = {
  orders: OrderWithCustomerDto[];
  hideCompany: boolean;
  isLoading: boolean;
};

const OrderList: React.FC<Props> = ({ orders, hideCompany, isLoading }) => {
  const { t } = useTranslation('customer');
  const { navigateTo } = useHistoryNavigation();

  const [page, setPage] = useIntParam('page', 0);
  const [rowsPerPage, setRowsPerPage] = useIntParam('pageSize', 10);

  const columns: TDataTableColumn<OrderWithCustomerDto>[] = [
    {
      title: t('Status'),
      render: (order) => (
        <Box
          component="span"
          sx={{ '>*': { verticalAlign: 'middle' } }}
        >
          {OrderStateIcon[order.state]}
        </Box>
      ),
    },
    { title: t('Online store'), render: (order) => order.process.customer.company, hidden: hideCompany },
    {
      title: t('Order ID'),
      render: (order) => (
        <Button
          onClick={() => navigateTo(`/customer/order/${order.orderId}`)}
          variant="outlined"
          size="small"
        >
          {order.externalOrderId}
        </Button>
      ),
    },
    { title: t('Customer name'), field: 'name' },
    { title: t('City'), field: 'city' },
    {
      title: t('Country'),
      render: (order) => {
        const countryCode = order.countryCode ?? order.process.processType.destinationCountry;
        return (
          <>
            <span>{countryToFlag(countryCode)}</span>&nbsp;{countryCode}
          </>
        );
      },
    },
    {
      title: t('Ordered on'),
      render: (rowData) => new Date(rowData.externalCreatedAt ?? rowData.createdAt).toLocaleDateString(),
    },
  ];

  return (
    <Paper sx={{ padding: 1 }}>
      <DataTable
        columns={columns}
        page={page}
        rows={orders}
        isLoading={isLoading}
      />

      <TablePagination
        component="div"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 30]}
        onRowsPerPageChange={(ev) => setRowsPerPage(parseInt(ev.target.value, 10))}
        page={page}
        count={orders.length < rowsPerPage ? page * rowsPerPage + orders.length : -1}
        onPageChange={(ev, page) => setPage(page)}
        showLastButton={true}
        showFirstButton={true}
      />
    </Paper>
  );
};

export default OrderList;
