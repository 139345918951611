import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-scale-timestack';
import 'chartjs-adapter-luxon';
import { Bar } from 'react-chartjs-2';
import { ShipmentType } from '../../../shared/backend';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { DashboardService } from '../../../services/dashboard-service/dashboard.service';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);

type Props = {
  customerId: number;
  destinationCountry: string;
  from: DateTime;
  to: DateTime;
};

const BarChartShipmentType: React.FC<Props> = ({ customerId, destinationCountry, from, to }) => {
  const { t } = useTranslation('reports');
  const theme = useTheme();

  const processedShipments = DashboardService.useProcessedShipments({ customerId, destinationCountry, from, to });
  const data = processedShipments.data.map((item) => ({ ...item, date: new Date(item.date) }));

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6">{t('Parcels by type')}</Typography>

      {processedShipments.isLoading && (
        <Skeleton
          variant="rectangular"
          height={300}
        />
      )}
      {processedShipments.data.length === 0 && !processedShipments.isLoading && !processedShipments.isError && (
        <Typography sx={{ textAlign: 'center', color: 'text.disabled' }}>{t('No data available')}</Typography>
      )}
      {processedShipments.isError && (
        <Alert severity="error">
          <AlertTitle>{t('Error while loading data')}</AlertTitle>

          {JSON.stringify(processedShipments.error, undefined, 2)}
        </Alert>
      )}
      {processedShipments.data.length > 0 && (
        <Bar
          options={{
            indexAxis: 'x' as const,
            scales: {
              x: {
                stacked: true,
                type: 'time',
                time: {
                  round: 'day',
                  minUnit: 'day',
                },
              },
              y: {
                stacked: true,
              },
            },
            responsive: true,
            plugins: {
              title: {
                display: false,
                text: t('Parcels by type'),
              },
              legend: {
                labels: {
                  usePointStyle: true,
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) =>
                    DateTime.fromMillis(context[0].parsed.x).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
                },
              },
            },
          }}
          data={{
            datasets: [
              {
                label: 'Outbound',
                data: data
                  .filter(({ type }) => type === ShipmentType.SHIPMENT)
                  .sort((a, b) => a.date.getTime() - b.date.getTime())
                  .map(({ date, count }) => ({
                    x: date.getTime(),
                    y: count,
                  })),
                backgroundColor: theme.palette.outbound.main,
              },
              {
                label: 'Inbound',
                data: data
                  .filter(({ type }) => type === ShipmentType.RETOUR)
                  .sort((a, b) => a.date.getTime() - b.date.getTime())
                  .map(({ date, count }) => ({
                    x: date.getTime(),
                    y: count,
                  })),
                backgroundColor: theme.palette.inbound.main,
              },
            ],
          }}
        />
      )}
    </Paper>
  );
};

export default BarChartShipmentType;
