import { Avatar, Step, StepContent, StepIconProps, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';

import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import GavelIcon from '@mui/icons-material/Gavel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingIcon from '@mui/icons-material/Pending';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import DrawIcon from '@mui/icons-material/Draw';

import { ShipmentWithOrderAndTourDto, ShipmentWithTourDto } from '#shipment-service';
import { ShipmentType } from '../../shared/backend';
import DeliveredIcon from '../../shared/DeliveredIcon';
import TrackingStateText from './TrackingStateText';

type Props = {
  shipment: ShipmentWithOrderAndTourDto;
};

export enum DisplayedTrackingState {
  DELIVERED = 'DELIVERED',
  IN_DEST_COUNTRY = 'IN_DEST_COUNTRY',
  CUSTOMS_CLEARED = 'CUSTOMS_CLEARED',
  IN_CUSTOMS_CLEARANCE = 'IN_CUSTOMS_CLEARANCE',
  PROCESSED = 'PROCESSED',
  RECORD_IN_SYSTEM = 'RECORD_IN_SYSTEM',
  ANNOUNCED = 'ANNOUNCED',
}

export const DISPLAYED_TRACKING_STATES = [
  DisplayedTrackingState.ANNOUNCED,
  DisplayedTrackingState.RECORD_IN_SYSTEM,
  DisplayedTrackingState.PROCESSED,
  DisplayedTrackingState.IN_CUSTOMS_CLEARANCE,
  DisplayedTrackingState.CUSTOMS_CLEARED,
  DisplayedTrackingState.IN_DEST_COUNTRY,
  DisplayedTrackingState.DELIVERED,
];

function getCurrentTrackingState(shipment: ShipmentWithTourDto): DisplayedTrackingState {
  if (shipment.deliveryDate) {
    return DisplayedTrackingState.DELIVERED;
  }
  if (shipment.carrierReceivedDate) {
    return DisplayedTrackingState.IN_DEST_COUNTRY;
  }
  if (shipment.isConsigned) {
    return DisplayedTrackingState.CUSTOMS_CLEARED;
  }
  if (shipment.tour?.createdAt) {
    return DisplayedTrackingState.IN_CUSTOMS_CLEARANCE;
  }
  if (shipment.processedAt) {
    return DisplayedTrackingState.PROCESSED;
  }
  if (shipment.createdAt) {
    return DisplayedTrackingState.RECORD_IN_SYSTEM;
  }
  return DisplayedTrackingState.ANNOUNCED;
}

export function getTrackingState(
  shipment: ShipmentWithOrderAndTourDto | ShipmentWithTourDto,
  state?: DisplayedTrackingState,
): {
  timestamp?: string | null;
  icon: React.JSX.Element;
  trackingState: DisplayedTrackingState;
} {
  const trackingState = state ?? getCurrentTrackingState(shipment);

  switch (trackingState) {
    case DisplayedTrackingState.DELIVERED:
      return { timestamp: shipment.deliveryDate, icon: <DeliveredIcon />, trackingState };

    case DisplayedTrackingState.IN_DEST_COUNTRY:
      return { timestamp: shipment.carrierReceivedDate, icon: <LocalShippingTwoToneIcon />, trackingState };

    case DisplayedTrackingState.CUSTOMS_CLEARED:
      return {
        timestamp: shipment.tour?.mrnDate,
        icon: <GavelIcon />,
        trackingState,
      };

    case DisplayedTrackingState.IN_CUSTOMS_CLEARANCE:
      return {
        timestamp: shipment.tour?.createdAt,
        icon: <DrawIcon />,
        trackingState,
      };

    case DisplayedTrackingState.PROCESSED:
      return { timestamp: shipment.processedAt, icon: <AssignmentTurnedInIcon />, trackingState };

    case DisplayedTrackingState.RECORD_IN_SYSTEM:
      return { timestamp: shipment.createdAt, icon: <FiberNewIcon />, trackingState };

    case DisplayedTrackingState.ANNOUNCED:
    default:
      return { timestamp: shipment.externalCreatedAt, icon: <PendingIcon />, trackingState };
  }
}

function OutboundTrackingStepIcon(props: StepIconProps) {
  return (
    <Avatar
      sx={(theme) => ({
        opacity: props.active ? 1 : props.completed ? 0.8 : undefined,
        bgcolor: props.active || props.completed ? theme.palette.outbound.main : undefined,
        marginLeft: -1,
      })}
    >
      {props.icon}
    </Avatar>
  );
}

function InboundTrackingStepIcon(props: StepIconProps) {
  return (
    <Avatar
      sx={(theme) => ({
        opacity: props.active ? 1 : props.completed ? 0.8 : undefined,
        bgcolor: props.active || props.completed ? theme.palette.inbound.main : undefined,
        marginLeft: -1,
      })}
    >
      {props.icon}
    </Avatar>
  );
}

function TrackingStep({
  shipment,
  stepIconComponent,
  state,
  description,
  ...props
}: {
  shipment: ShipmentWithOrderAndTourDto;
  stepIconComponent: React.ElementType<StepIconProps>;
  state: DisplayedTrackingState;
  description?: string;
}) {
  const { icon, timestamp } = getTrackingState(shipment, state);

  return (
    <Step {...props}>
      <StepLabel
        StepIconComponent={stepIconComponent}
        icon={icon}
        optional={timestamp ? new Date(timestamp).toLocaleString() : undefined}
      >
        <TrackingStateText state={state} />
      </StepLabel>
      {description && (
        <StepContent>
          <Typography pl={1}>{description}</Typography>
        </StepContent>
      )}
    </Step>
  );
}

const TrackingFlowChart: React.FC<Props> = ({ shipment }) => {
  const currentState = getCurrentTrackingState(shipment);
  const stepIconComponent =
    shipment.type === ShipmentType.SHIPMENT ? OutboundTrackingStepIcon : InboundTrackingStepIcon;

  return (
    <Stepper
      orientation="vertical"
      activeStep={DISPLAYED_TRACKING_STATES.indexOf(currentState)}
      sx={{ flexDirection: 'column-reverse' }}
    >
      <TrackingStep
        state={DisplayedTrackingState.ANNOUNCED}
        {...{ shipment, stepIconComponent }}
      />

      <TrackingStep
        state={DisplayedTrackingState.RECORD_IN_SYSTEM}
        {...{ shipment, stepIconComponent }}
      />

      <TrackingStep
        state={DisplayedTrackingState.PROCESSED}
        {...{ shipment, stepIconComponent }}
      />

      <TrackingStep
        state={DisplayedTrackingState.IN_CUSTOMS_CLEARANCE}
        {...{ shipment, stepIconComponent }}
      />

      <TrackingStep
        state={DisplayedTrackingState.CUSTOMS_CLEARED}
        {...{ shipment, stepIconComponent }}
      />

      <TrackingStep
        state={DisplayedTrackingState.IN_DEST_COUNTRY}
        {...{ shipment, stepIconComponent }}
      />

      <TrackingStep
        state={DisplayedTrackingState.DELIVERED}
        {...{ shipment, stepIconComponent }}
      />
    </Stepper>
  );
};

export default TrackingFlowChart;
