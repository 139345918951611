import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShipmentWithOrderAndTourDto } from '#shipment-service';
import BreadcrumbsGridItem from '../crm/BreadcrumbsGridItem';
import { useHistoryRef } from '../../hooks/useHistoryNavigation';

type Props = {
  shipment: ShipmentWithOrderAndTourDto;
};

const ShipmentBreadCrumbs: React.FC<Props> = ({ shipment }) => {
  const { t } = useTranslation('customer');
  const historyRef = useHistoryRef();

  return (
    <Grid
      container
      spacing={3}
    >
      {shipment && (
        <BreadcrumbsGridItem
          items={[
            { href: historyRef ?? '/customer/search', label: t('Shipment tracking') },
            {
              href: `/customer/order/${shipment.order.orderId}`,
              label: `${t('Order {{orderId}}', { orderId: shipment.order.externalOrderId || shipment.order.orderId })}`,
            },
            {
              label: `${t('Shipment {{shipmentId}}', { shipmentId: shipment.externalShipmentId || shipment.shipmentId })}`,
            },
          ]}
        />
      )}
    </Grid>
  );
};

export default ShipmentBreadCrumbs;
