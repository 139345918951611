import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserInfo } from '#user-service';
import { ProcessService } from '../../services/process-service/process.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useIntListParam, useIntParam, useStringParam } from '../../hooks/useParam';
import { ShipmentService } from '#shipment-service';
import { OrderService } from '#order-service';
import TrackingSearch, { TrackingSearchValues } from './TrackingSearch';
import ShipmentList from './ShipmentList';
import OrderList from './OrderList';
import { useHistoryNavigation } from '../../hooks/useHistoryNavigation';
import { Helmet } from 'react-helmet-async';

export const CustomerSearchPage = () => {
  const { t } = useTranslation('customer');
  const userInfo = useUserInfo();
  const auth = useAuthentication();
  const { navigateTo } = useHistoryNavigation();
  const processes = ProcessService.useProcesses();

  const [page] = useIntParam('page', 0);
  const [pageSize] = useIntParam('pageSize', 10);

  const [trackingNumber, setTrackingNumber] = useStringParam('trackingNumber', '');
  const [orderNumber, setOrderNumber] = useStringParam('orderNumber', '');
  const [name, setName] = useStringParam('name', '');
  const [customerIds, setCustomerIds] = useIntListParam('customerIds', []);

  const [isSearchTriggeredByUser, setIsSearchTriggeredByUser] = useState(false);

  const onSearchParamsChange = (values: TrackingSearchValues): void => {
    setTrackingNumber(values.trackingNumber);
    setOrderNumber(values.orderNumber);
    setName(values.name);
    setCustomerIds(values.customerIds);

    setIsSearchTriggeredByUser(true);
  };

  const relevantCustomerIds = userInfo.data?.customerId ? [userInfo.data.customerId] : customerIds;
  const processIds =
    customerIds.length === 0
      ? undefined
      : processes.data
          .filter((process) => relevantCustomerIds.includes(process.customerId))
          .map((process) => process.processId);

  const isShipmentSearch = !!trackingNumber;
  const isEmptySearch = !trackingNumber && !orderNumber && !name && (auth.isCustomer() || !customerIds.length);

  const shipments = ShipmentService.useTrackingShipments(
    isShipmentSearch
      ? {
          externalOrderId: orderNumber ? orderNumber : undefined,
          name: name ? name : undefined,
          trackingNumber,
          limit: pageSize,
          offset: page * pageSize,
          processIds,
        }
      : undefined,
  );

  const orders = OrderService.useTrackingOrders(
    !isShipmentSearch && !isEmptySearch
      ? {
          externalOrderId: orderNumber ? orderNumber : undefined,
          name: name ? name : undefined,
          offset: page * pageSize,
          limit: pageSize,
          processIds,
        }
      : undefined,
  );

  const singleShipmentId = shipments.data.length === 1 ? shipments.data[0].shipmentId : null;
  const singleOrderId = orders.data.length === 1 ? orders.data[0].orderId : null;

  useEffect(() => {
    if (singleShipmentId && isSearchTriggeredByUser) {
      navigateTo(`/customer/shipment/${singleShipmentId}`);
    }
  }, [isSearchTriggeredByUser, navigateTo, singleShipmentId]);

  useEffect(() => {
    if (singleOrderId && isSearchTriggeredByUser) {
      navigateTo(`/customer/order/${singleOrderId}`);
    }
  }, [isSearchTriggeredByUser, navigateTo, singleOrderId]);

  useEffect(() => {
    setIsSearchTriggeredByUser(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('Order and tracking')}</title>
      </Helmet>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Paper sx={{ px: 2, py: 2 }}>
            <Typography
              variant="h5"
              gutterBottom
            >
              {t('Order and shipment tracking')}
            </Typography>
            <Typography
              variant="body1"
              mb={3}
            >
              {t('Here you can search for tracking numbers, orders or names. The search may take several seconds.')}
            </Typography>

            <TrackingSearch
              trackingNumber={trackingNumber}
              orderNumber={orderNumber}
              name={name}
              customerIds={customerIds}
              onSubmit={onSearchParamsChange}
              isLoading={shipments.isLoading || orders.isLoading}
            />
          </Paper>
        </Grid>

        <Grid
          item
          xs={12}
        >
          {isShipmentSearch && !isEmptySearch && shipments.data && (
            <ShipmentList
              hideCompany={relevantCustomerIds.length === 1}
              shipments={shipments.data}
              isLoading={shipments.isLoading}
            ></ShipmentList>
          )}

          {!isShipmentSearch && !isEmptySearch && orders.data && (
            <OrderList
              hideCompany={relevantCustomerIds.length === 1}
              orders={orders.data}
              isLoading={orders.isLoading}
            ></OrderList>
          )}
        </Grid>
      </Grid>
    </>
  );
};
