import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js';
import 'chartjs-scale-timestack';
import 'chartjs-adapter-luxon';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { DashboardService } from '../../../services/dashboard-service/dashboard.service';
import { CategoryScale } from 'chart.js';
import _ from 'lodash';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler);

type Props = {
  customerId: number;
  destinationCountry: string;
  from: DateTime;
  to: DateTime;
};

const LineChartCarrierTime: React.FC<Props> = ({ customerId, destinationCountry, from, to }) => {
  const { t } = useTranslation('reports');
  const theme = useTheme();

  const carrierTimes = DashboardService.useCarrierTime({ customerId, destinationCountry, from, to });
  const data = carrierTimes.data.map((item) => ({ ...item, date: new Date(item.date) }));

  const tension = 0.3;

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6">{t('Average runtime (outbound)')}</Typography>

      {carrierTimes.isLoading && (
        <Skeleton
          variant="rectangular"
          height={300}
        />
      )}
      {carrierTimes.data.length === 0 && !carrierTimes.isLoading && !carrierTimes.isError && (
        <Typography sx={{ textAlign: 'center', color: 'text.disabled' }}>{t('No data available')}</Typography>
      )}
      {carrierTimes.isError && (
        <Alert severity="error">
          <AlertTitle>{t('Error while loading data')}</AlertTitle>

          {JSON.stringify(carrierTimes.error, undefined, 2)}
        </Alert>
      )}
      {carrierTimes.data.length > 0 && (
        <Line
          options={{
            indexAxis: 'x' as const,
            scales: {
              x: {
                type: 'time',
                time: {
                  round: 'day',
                  unit: 'day',
                },
              },
              y: {
                type: 'linear' as const,
              },
            },
            responsive: true,
            plugins: {
              title: {
                display: false,
                text: t('Average runtime'),
              },
              legend: {
                labels: {
                  usePointStyle: true,
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) =>
                    DateTime.fromMillis(context[0].parsed.x).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY),
                },
              },
            },
          }}
          data={{
            datasets: [
              {
                label: t('Avg. carrier'),
                data: data.map(({ date, avgCarrierRuntimeMinutes }) => ({
                  x: date.getTime(),
                  y: _.round(avgCarrierRuntimeMinutes / 60 / 24, 2),
                })),
                tension,
                fill: false,
                backgroundColor: theme.palette.secondary.light,
                borderColor: theme.palette.secondary.main,
              },
              {
                label: t('Avg. end-to-end'),
                data: data.map(({ date, avgEndToEndMinutes }) => ({
                  x: date.getTime(),
                  y: _.round(avgEndToEndMinutes / 60 / 24, 2),
                })),
                tension,
                fill: false,
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.main,
              },
            ],
          }}
        />
      )}
    </Paper>
  );
};

export default LineChartCarrierTime;
