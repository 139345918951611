import React from 'react';
import { TourAndChecksDto } from '../../../../services/tour-service/tour.service';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import CopyToClipboardButton from '../../../../shared/components/CopyToClipboardButton';
import { TourCustomsState } from '../../../../shared/backend';

type Props = {
  tour: TourAndChecksDto['tour'];
  checks: TourAndChecksDto['checks'];
};

const TourIdCell: React.FC<Props> = ({ tour }) => {
  return (
    <>
      <Button
        component={Link}
        to={`/customs/tour-details/${tour.tourId}/${
          tour.exportCustomsState === TourCustomsState.CONSIGNMENT_NOT_SENT ? 'shipments' : 'customs'
        }`}
        variant="text"
        size="small"
        color="primary"
        sx={{ ml: -1.5 }}
      >
        {tour.tourId}
      </Button>
      <CopyToClipboardButton
        value={tour.tourId.toString()}
        size="small"
      />
    </>
  );
};

export default TourIdCell;
