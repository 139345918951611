import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineItemDto } from '../../services/line-item-service/lineItem.service';
import DataTable, { TDataTableColumn } from '../../shared/components/data-table/DataTable';
import { IsoCurrencyCode } from '../../shared/backend';

type Props = {
  heading?: string;
  subheading?: string;
  lineItems: LineItemDto[];
  currency: IsoCurrencyCode;
};

const LineItemTable: React.FC<Props> = ({ lineItems, heading, subheading, currency }) => {
  const { t } = useTranslation('customer');

  const columns: TDataTableColumn<LineItemDto>[] = [
    {
      title: t('Article'),
      field: 'name',
    },
    { title: `${t('Price')} (${currency})`, field: 'priceGross' },
    {
      title: t('Amount'),
      field: 'quantity',
    },
  ];

  return (
    <Card sx={{ boxShadow: 'none', border: '1px solid', borderColor: 'divider' }}>
      {heading && (
        <CardHeader
          title={heading}
          subheader={subheading}
        />
      )}

      <CardContent>
        <DataTable
          columns={columns}
          rows={lineItems}
        />
      </CardContent>
    </Card>
  );
};

export default LineItemTable;
