import { Box, CircularProgress, Grid, Paper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentService } from '#shipment-service';
import ShipmentHeader from './ShipmentHeader';
import TrackingFlowChart from './TrackingFlowChart';
import TrackingSideBar from './TrackingSideBar';
import LineItemTable from './LineItemTable';
import { useParams } from 'react-router-dom';
import ErrorPage from '../../shared/components/ErrorPage';
import ShipmentBreadCrumbs from './ShipmentBreadCrumbs';

const ShipmentDetails: React.FC = () => {
  const { t } = useTranslation('customer');

  const { shipmentId } = useParams();
  const parsedShipmentId = parseInt(shipmentId ?? '', 10);

  const shipment = ShipmentService.useShipment(!isNaN(parsedShipmentId) ? parsedShipmentId : null);

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        {shipment.data && <ShipmentBreadCrumbs shipment={shipment.data}></ShipmentBreadCrumbs>}
      </Grid>

      <Grid
        item
        xs={12}
        xl={6}
      >
        <Paper sx={{ padding: 2 }}>
          {shipment.data && (
            <>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  marginBottom={6}
                >
                  <ShipmentHeader shipment={shipment.data} />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={8}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                    <TrackingFlowChart shipment={shipment.data} />
                  </Box>
                </Grid>

                <Grid
                  sx={{ borderLeft: '1px solid', borderColor: 'divider' }}
                  item
                  xs={12}
                  md={4}
                >
                  <TrackingSideBar
                    order={shipment.data.order}
                    shipment={shipment.data}
                  ></TrackingSideBar>
                </Grid>

                <Grid
                  item
                  xs={12}
                  marginTop={6}
                >
                  <LineItemTable
                    heading={t('Line items')}
                    subheading={t('Those line items are included in this shipment')}
                    lineItems={shipment.data.lineItems}
                    currency={shipment.data.order.currency}
                  ></LineItemTable>
                </Grid>
              </Grid>
            </>
          )}

          {shipment.isLoading && <CircularProgress />}

          {!shipment.isLoading && !shipment.data && (
            <ErrorPage
              title={t('Shipment not found')}
              content={t('But at least you can look at a sweet kitten.')}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ShipmentDetails;
