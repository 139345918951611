import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import DashboardGrid from './DashboardGrid';

type Props = {};

const DashboardPage: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('Dashboard')}</title>
      </Helmet>
      <DashboardGrid />
    </>
  );
};

export default DashboardPage;
