import React, { useEffect, useRef } from 'react';
import { useNotifications } from './hooks/useNotifications';
import { useAuthentication } from './hooks/useAuthentication';
import { backendUri } from './shared/helper/env/helper';
import { useTranslation } from 'react-i18next';
import { UserRole } from './shared/backend';

type Props = {};

const DocumentSubscriber: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { userId, role } = useAuthentication();
  const notifications = useNotifications();
  const eventSource = useRef<EventSource>();

  useEffect(() => {
    if (!userId) {
      eventSource.current?.close();
      eventSource.current = undefined;

      return;
    }

    if (eventSource.current) {
      return;
    }

    if (role !== UserRole.ADMIN) {
      return;
    }

    eventSource.current = new EventSource(`${backendUri}/document/events`, {
      withCredentials: true,
    });

    eventSource.current.onopen = () => console.log('Event source is open');

    eventSource.current.addEventListener('completed', (ev) => {
      const data = JSON.parse(ev.data);

      if (data.filename) {
        // data.options can be used to get download link
        notifications.addInfo(t('File {{filename}} is ready for download', { filename: data.filename }));
      }
    });
  }, [userId, notifications, t, role]);

  return <></>;
};

export default DocumentSubscriber;
