import { MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessTypeService } from '../../services/process-type-service/process-type.service';
import { countryToFlag } from '../../shared/helper/country';
import { CountryCode } from '../../shared/backend';
import { CustomerService } from '#customer-service';
import SelectWithFilter from '../../shared/components/SelectWithFilter';
import { useCountryParam, useCustomerIdParam, useFromParam, useToParam } from './dashboard.params';

type Props = {};

const DashboardHeader: React.FC<Props> = () => {
  const { t } = useTranslation('reports');

  const customers = CustomerService.useCustomers();

  const [customerId, setCustomerId] = useCustomerIdParam();

  const customerProcessTypeIds = customers.data
    .filter((customer) => customerId === customer.customerId)
    .flatMap((customer) => customer.processes.map(({ processTypeId }) => processTypeId));

  const processTypes = ProcessTypeService.useProcessTypes();
  const destinationCountries = [
    ...new Set(
      processTypes.data
        .filter(({ processTypeId }) => customerProcessTypeIds.includes(processTypeId))
        .map((processType) => processType.destinationCountry),
    ),
  ]
    .filter((country) => country !== CountryCode.ANY)
    .sort((a, b) => a.localeCompare(b));

  const [destinationCountry, setDestinationCountry] = useCountryParam();

  const now = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [from, setFrom] = useFromParam();
  const [to, setTo] = useToParam();

  const timeRanges = {
    last7Days: [now.minus({ days: 7 }), now],
    lastWeek: [now.startOf('week').minus({ week: 1 }), now.endOf('week').minus({ week: 1 })],
    last4Weeks: [now.minus({ weeks: 4 }), now],
    thisMonth: [now.startOf('month'), now],
    lastMonth: [now.minus({ month: 1 }).startOf('month'), now.minus({ month: 1 }).endOf('month')],
  } as const;

  const timeRange =
    Object.keys(timeRanges).find(
      (key) =>
        from &&
        to &&
        timeRanges[key as keyof typeof timeRanges][0].toISODate() === from.toISODate() &&
        timeRanges[key as keyof typeof timeRanges][1].toISODate() === to.toISODate(),
    ) ?? 'custom';

  useEffect(() => {
    if (destinationCountries.length === 1) {
      setDestinationCountry(destinationCountries[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationCountries.join(',')]);

  return (
    <Paper sx={{ px: 2, py: 3 }}>
      <Typography
        variant="h5"
        gutterBottom
      >
        {t('Dashboard')}
      </Typography>
      <Typography
        variant="body1"
        mb={3}
      >
        {t('Our dashboard shows an overview your current performance with exporto.')}
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="flex-end"
        spacing={1}
      >
        <SelectWithFilter
          label={t('Customer')}
          values={customers.data
            .map(({ customerId, company }) => ({
              id: customerId,
              label: company,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          selectedValueIds={customerId ? [customerId] : []}
          onChange={([id]) => {
            setDestinationCountry(undefined);
            setCustomerId(id ?? 0);
          }}
          sx={{ minWidth: 210 }}
        />

        <TextField
          select
          label={t('Country')}
          value={destinationCountry}
          onChange={(ev) => setDestinationCountry(ev.target.value)}
          disabled={!destinationCountries.length}
          sx={{ minWidth: 100 }}
        >
          {destinationCountries.map((country) => (
            <MenuItem
              key={country}
              value={country}
            >
              {countryToFlag(country)} {country}
            </MenuItem>
          ))}
          {destinationCountry && !destinationCountries.includes(destinationCountry) && (
            <MenuItem value={destinationCountry}>
              {countryToFlag(destinationCountry)} {destinationCountry}
            </MenuItem>
          )}
        </TextField>

        <TextField
          select
          label={t('Time range')}
          value={timeRange}
          onChange={(ev) => {
            const selectedRange = ev.target.value;

            if (selectedRange in timeRanges) {
              const [selectedFrom, selectedTo] = timeRanges[selectedRange as keyof typeof timeRanges];

              setFrom(selectedFrom);
              setTo(selectedTo);
            }
          }}
        >
          <MenuItem value="last7Days">{t('Last 7 days')}</MenuItem>
          <MenuItem value="lastWeek">{t('Last week')}</MenuItem>
          <MenuItem value="last4Weeks">{t('Last 4 weeks')}</MenuItem>
          <MenuItem value="thisMonth">{t('This month')}</MenuItem>
          <MenuItem value="lastMonth">{t('Last month')}</MenuItem>
          {timeRange === 'custom' && <MenuItem value="custom">{t('Custom')}</MenuItem>}
        </TextField>
      </Stack>
    </Paper>
  );
};

export default DashboardHeader;
