import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { useAuthentication } from '../../hooks/useAuthentication';
import { OrderDto, OrderWithCustomerDto } from '#order-service';
import { ShipmentWithOrderAndTourDto } from '#shipment-service';
import { getDisplayedTrackingId } from './OrderShipment';
import { getTrackingUrl } from '../orders/shared/components/order-detail-modal/components/shipment-view/components/TrackingInformation';
import { ProcessService } from '../../services/process-service/process.service';
import CopyToClipboardButton from '../../shared/components/CopyToClipboardButton';

type Props = {
  order: OrderWithCustomerDto | OrderDto;
  shipment?: ShipmentWithOrderAndTourDto;
};

const TrackingSideBar: React.FC<Props> = ({ order, shipment }) => {
  const { t } = useTranslation('customer');
  const auth = useAuthentication();
  const process = ProcessService.useProcess(order.processId);

  const trackingId = shipment ? getDisplayedTrackingId(shipment) : null;
  const trackingUrl = trackingId ? getTrackingUrl(trackingId) : null;

  return (
    <Stack spacing={3}>
      {auth.isStaff() && (
        <Box>
          {process.data && (
            <>
              <Typography gutterBottom>{`${process.data.customer.company}`}</Typography>
            </>
          )}

          <Typography gutterBottom>
            <Link to={`/orders/all-orders?orderId=${order.orderId}`}>{t('View order')}</Link>
          </Typography>
        </Box>
      )}

      <Box>
        <Typography
          variant="h6"
          gutterBottom
        >
          {t('Customer data')}
        </Typography>

        <Typography>{order.name}</Typography>

        <Typography>{order.address}</Typography>

        {order.additionalAddress && <Typography>{order.additionalAddress}</Typography>}

        <Typography>{`${order.zip ?? ''} ${order.city ?? ''}`}</Typography>

        <Typography>
          {order.countryCode ?? ('process' in order ? order.process.processType.destinationCountry : undefined) ?? ''}
        </Typography>
      </Box>

      {shipment && (
        <>
          <Box>
            <Typography
              variant="h6"
              gutterBottom
            >
              {t('Carrier data')}
            </Typography>

            {trackingId && (
              <Box>
                <Typography variant="body2">{`${t('Tracking Id')}:`}</Typography>
                {trackingUrl && (
                  <Typography gutterBottom>
                    <Link to={trackingUrl}> {trackingId}</Link>
                  </Typography>
                )}
                {!trackingUrl && <Typography gutterBottom> {trackingId}</Typography>}
              </Box>
            )}
          </Box>

          <Box>
            <Typography
              variant="h6"
              gutterBottom
            >
              {t('Shipment data')}
            </Typography>

            <Typography variant="body2">{`${t('Shipment ID')}:`}</Typography>
            <Typography gutterBottom>
              {shipment.externalShipmentId ?? '-'}
              <CopyToClipboardButton
                size="small"
                value={shipment.externalShipmentId ?? ''}
              />
            </Typography>

            <Typography variant="body2">{`${t('Shipment ID (exporto)')}:`}</Typography>
            <Typography gutterBottom>{shipment.shipmentId}</Typography>
          </Box>
        </>
      )}

      <Box>
        <Typography
          variant="h6"
          gutterBottom
        >
          {t('Order data')}
        </Typography>

        <Typography>{`${t('Customer facing ID')}:`}</Typography>
        <Typography gutterBottom>
          {`${order.customerFacingId}`}
          {order.customerFacingId && (
            <CopyToClipboardButton
              size="small"
              value={order.customerFacingId}
            />
          )}
        </Typography>

        <Typography>{`${t('Order ID')}:`}</Typography>
        <Typography gutterBottom>
          {`${order.externalOrderId}`}
          <CopyToClipboardButton
            size="small"
            value={order.externalOrderId}
          />
        </Typography>

        <Typography variant="body2">{`${t('Order ID (exporto)')}:`}</Typography>
        <Typography gutterBottom>{order.orderId}</Typography>

        {order.externalCreatedAt && (
          <>
            <Typography>{`${t('Ordered on')}:`}</Typography>
            <Typography gutterBottom>{`${new Date(order.externalCreatedAt).toLocaleString()}`}</Typography>
          </>
        )}

        <Typography>{`${t('Created by exporto on')}:`}</Typography>
        <Typography gutterBottom>{`${new Date(order.createdAt).toLocaleString()}`}</Typography>
      </Box>
    </Stack>
  );
};

export default TrackingSideBar;
