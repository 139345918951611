import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderWithCustomerDto } from '#order-service';
import { OrderStateIcon } from '../orders/all-orders/components/OrderStateIcons';

type Props = {
  order: OrderWithCustomerDto;
};

const OrderHeader: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation('customer');
  const orderId = order.externalOrderId || order.orderId;

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Avatar>{OrderStateIcon[order.state]}</Avatar>

      <Typography variant="h5">{t('Order {{orderId}}', { orderId })}</Typography>
    </Stack>
  );
};

export default OrderHeader;
