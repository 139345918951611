import { Alert, AlertTitle, Box, Paper, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useTranslation } from 'react-i18next';
import { DashboardService } from '../../../services/dashboard-service/dashboard.service';

type Props = {
  customerId: number;
};

const TicketCount: React.FC<Props> = ({ customerId }) => {
  const { t } = useTranslation('reports');
  const [isPartyTime, setIsPartyTime] = useState(false);

  const ticketCount = DashboardService.useTicketCount({ customerId });

  useEffect(() => {
    if (ticketCount.data?.pending === 0) {
      const timeout = setTimeout(() => setIsPartyTime(true), 1000);

      return () => clearTimeout(timeout);
    }
  }, [ticketCount.data?.pending]);

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6">{t('Pending tickets')}</Typography>

      {ticketCount.isLoading && (
        <Skeleton
          variant="rectangular"
          height={300}
        />
      )}
      {!ticketCount.data && !ticketCount.isLoading && !ticketCount.isError && (
        <Typography sx={{ textAlign: 'center', color: 'text.disabled' }}>{t('No data available')}</Typography>
      )}
      {ticketCount.isError && (
        <Alert severity="error">
          <AlertTitle>{t('Error while loading data')}</AlertTitle>

          {JSON.stringify(ticketCount.error, undefined, 2)}
        </Alert>
      )}

      {ticketCount.data && (
        <>
          <Box
            paddingTop={6}
            paddingBottom={3}
            sx={{ fontSize: '1.5rem' }}
          >
            {isPartyTime && (
              <Box
                display="flex"
                justifyContent="center"
              >
                <Box>
                  <ConfettiExplosion
                    force={0.4}
                    duration={2000}
                    particleCount={25}
                    width={300}
                  />
                </Box>
              </Box>
            )}
            <Typography sx={{ textAlign: 'center', fontSize: '1.8em' }}>{ticketCount.data.pending}</Typography>
          </Box>

          <Typography
            variant="body2"
            color="text.disabled"
            textAlign="center"
          >
            {t('Overall we closed {{count}} tickets.', { count: ticketCount.data.closed })}
          </Typography>
        </>
      )}
    </Paper>
  );
};

export default TicketCount;
