import { DateTime } from 'luxon';
import { useDateParam, useIntParam, useStringParam } from '../../hooks/useParam';
import { useUserInfo } from '#user-service';

export const useFromParam = () => {
  const now = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return useDateParam('from', now.minus({ weeks: 4 }));
};

export const useToParam = () => {
  const now = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  return useDateParam('to', now);
};

export const useCustomerIdParam = () => {
  const userInfo = useUserInfo();

  return useIntParam('customerId', userInfo.data?.customerId ?? 0);
};

export const useCountryParam = () => {
  return useStringParam('country');
};
