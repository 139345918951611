import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BreadcrumbsGridItem from '../crm/BreadcrumbsGridItem';
import { useHistoryRef } from '../../hooks/useHistoryNavigation';
import { OrderWithCustomerDto } from '#order-service';

type Props = {
  order: OrderWithCustomerDto;
};

const OrderBreadCrumbs: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation('customer');
  const historyRef = useHistoryRef();

  return (
    <Grid
      container
      spacing={3}
    >
      <BreadcrumbsGridItem
        items={[
          { href: historyRef ?? '/customer/search', label: t('Shipment tracking') },
          {
            label: `${t('Order {{orderId}}', { orderId: order.externalOrderId || order.orderId })}`,
          },
        ]}
      />
    </Grid>
  );
};

export default OrderBreadCrumbs;
