import { AxiosResponse } from 'axios';
import { backendUri } from '../../shared/helper/env/helper';
import { IConsignmentParty, IPersonInCharge, IVehicleIdentification } from './customs.types';
import useSWR from 'swr';
import client, { IUseArrayResponse, SchemaType, api, useApi, IUseObjectResponse } from '../../shared/client';
import { jobService } from '../job-service/job.service';
import { ConsignmentType } from '../../shared/backend';

export interface ConsignmentPersonInChargeDto extends SchemaType<'ConsignmentPersonInChargeDto'> {}
export interface ConsignmentVehicleIdentificationDto extends SchemaType<'ConsignmentVehicleIdentificationDto'> {}
export interface ConsignmentPartyDto extends SchemaType<'ConsignmentPartyDto'> {}
export interface CreatedJobDataResponseDto extends SchemaType<'CreatedJobDataResponseDto'> {}
export interface CreateDeImportDocumentsRequestDto extends SchemaType<'CreateDeImportDocumentsRequestDto'> {}
export interface CreateEmkDocumentsDto extends SchemaType<'CreateEmkDocumentsDto'> {}
export interface UpdateTourCustomsStatesDto extends SchemaType<'UpdateTourCustomsStatesDto'> {}
export interface CreatePostNordCsvDto extends SchemaType<'CreatePostNordCsvDto'> {}
export interface TourBatchInvoiceDto extends SchemaType<'CreateTourBatchInvoiceDto'> {}
export interface TourInvoicesDto extends SchemaType<'CreateTourInvoicesDto'> {}
export interface ShipmentDutiesAndVatResponseDto extends SchemaType<'ShipmentDutiesAndVatResponseDto'> {}
export interface ShipmentDutiesAndVatDto extends SchemaType<'ShipmentDutiesAndVatDto'> {}
export interface TourCustomsStatesDto extends SchemaType<'TourCustomsStatesDto'> {}
export interface TourCustomsStateDto extends SchemaType<'TourCustomsStateDto'> {}
export interface DeclarationInfoDto extends SchemaType<'DeclarationInfoDto'> {}
export interface ShipmentWithMissingDeclarationDto extends SchemaType<'ShipmentWithMissingDeclarationDto'> {}

export interface CreateConsignmentVehicleIdentificationDto
  extends SchemaType<'CreateConsignmentVehicleIdentificationDto'> {}

interface CreateConsignmentsRequestDto extends SchemaType<'CreateConsignmentsRequestDto'> {}

export interface ICreatePersonInChargeDto {
  forename: string;
  surname: string;
  email: string;
  phone: string;
  initials: string;
}

export interface ICreateConsignmentCarrier {
  name: string;
  name2?: string;
  street: string;
  city: string;
  postCode: string;
  country: string;
  companyNumber?: string;
  eoriNumber?: string;
}

export interface IAdditionalConsignmentData {
  personInChargeId?: number;
  vehicleIdentificationId?: number;
  carrierId?: number;
  transitConsignorId?: number;
  shippingPointId?: number;
  customsRegistrationNumber?: string;
}

export namespace CustomsService {
  export const useLastConsignmentUpdate = () => {
    const { data, error, mutate, isLoading, isValidating } = useSWR<{ updatedAt: string }>(
      `v1/customs/last-declaration-update`,
    );

    return {
      data,
      error,
      isLoading,
      isValidating,
      isError: !!error,
      mutate,
    };
  };

  /**
   * Creates a consignment at AEB for the specified tour.
   * @param tourIds
   * @param consignmentType
   * @param additionalConsignmentData
   */
  export async function createConsignments(
    tourIds: number[],
    consignmentType: ConsignmentType,
    additionalConsignmentData: IAdditionalConsignmentData,
  ): Promise<CreatedJobDataResponseDto> {
    const {
      personInChargeId,
      vehicleIdentificationId,
      carrierId,
      transitConsignorId,
      shippingPointId,
      customsRegistrationNumber,
    } = additionalConsignmentData;

    const req: CreateConsignmentsRequestDto = {
      tourIds,
      consignmentType,
      personInChargeId,
      vehicleIdentificationId,
      carrierId,
      transitConsignorId,
      shippingPointId,
      customsRegistrationNumber,
    };

    return (await api.post<'/v1/customs/create-consignments'>(`${backendUri}/v1/customs/create-consignments`, req))
      .data;
  }

  export const synchronizeDeclarations = async (
    consignmentId: number,
  ): Promise<{ jobId: string; queueName: string }> => {
    const response = await api.post<'/v1/customs/consignment/{consignmentId}/synchronize-declarations'>(
      `${backendUri}/v1/customs/consignment/${consignmentId}/synchronize-declarations`,
    );

    return {
      jobId: response.data.jobId,
      queueName: response.data.queueName,
    };
  };

  export const getTourCustomsStateUrl = (tourId: number) => `${backendUri}/v1/tour/${tourId}/customsState`;

  export const useTourCustomsState = (tourId: number | null) => {
    const url = tourId ? getTourCustomsStateUrl(tourId) : null;

    const { data, error, mutate } = useSWR<TourCustomsStatesDto>(url, {
      refreshInterval: 30000,
    });

    return {
      data,
      error,
      isLoading: !!url && !error && !data,
      isError: !!error,
      mutate,
    };
  };

  export const createGermanImportDocuments = async (
    options: CreateDeImportDocumentsRequestDto,
  ): Promise<CreatedJobDataResponseDto> => {
    return (
      await api.post<'/v1/customs/create-german-import-documents'>(
        `${backendUri}/v1/customs/create-german-import-documents`,
        options,
      )
    ).data;
  };

  export const updateCustomsStates = async (options: UpdateTourCustomsStatesDto): Promise<void> => {
    await jobService.addJobPromise((await createCustomsStatesJob(options)).jobId.toString());
  };

  const createCustomsStatesJob = async (options: UpdateTourCustomsStatesDto): Promise<CreatedJobDataResponseDto> => {
    return (
      await api.post<'/v1/customs/update-tour-customs-states'>(
        `${backendUri}/v1/customs/update-tour-customs-states`,
        options,
      )
    ).data;
  };

  export const createEmkDocuments = async (options: CreateEmkDocumentsDto): Promise<CreatedJobDataResponseDto> => {
    return (await api.post<'/v1/customs/emk-documents'>(`${backendUri}/v1/customs/emk-documents`, options)).data;
  };

  export const createTourBatchInvoices = async (options: TourBatchInvoiceDto): Promise<CreatedJobDataResponseDto> => {
    return (await api.post<'/v1/customs/tour-batch-invoices'>(`${backendUri}/v1/customs/tour-batch-invoices`, options))
      .data;
  };

  export const createTourInvoices = async (options: TourInvoicesDto): Promise<CreatedJobDataResponseDto> => {
    return (
      await api.post<'/v1/customs/create-tour-invoices'>(`${backendUri}/v1/customs/create-tour-invoices`, options)
    ).data;
  };

  export const createCollectiveReferences = async (tourIds: number[]): Promise<CreatedJobDataResponseDto> => {
    return (
      await api.post<'/v1/customs/create-collective-references'>(
        `${backendUri}/v1/customs/create-collective-references`,
        { tourIds },
      )
    ).data;
  };

  export const createTransitDepartureDocument = async (tourIds: number[]): Promise<CreatedJobDataResponseDto> => {
    return (
      await api.post<'/v1/customs/create-transit-departure-document'>(
        `${backendUri}/v1/customs/create-transit-departure-document`,
        { tourIds },
      )
    ).data;
  };

  export const createPostNordCsv = async (options: CreatePostNordCsvDto): Promise<CreatedJobDataResponseDto> => {
    return (await api.post<'/v1/customs/post-nord-csv'>(`${backendUri}/v1/customs/post-nord-csv`, options)).data;
  };

  export const deleteConsignment = async (consignmentId: number): Promise<void> => {
    await api.delete(`${backendUri}/v1/customs/consignment/${consignmentId}`);
  };

  export const relinkConsignment = async (consignmentId: number, externalConsignmentId: string): Promise<void> => {
    await api.post(`${backendUri}/v1/customs/consignment/${consignmentId}/relink/${externalConsignmentId}`);
  };

  export const deleteConsignmentWarnings = async (consignmentId: number): Promise<void> => {
    await api.delete(`${backendUri}/v1/customs/consignment/${consignmentId}/warnings`);
  };

  export const useVehicleIdentifications = (): IUseArrayResponse<ConsignmentVehicleIdentificationDto> => {
    const { data, mutate, error, isLoading } = useApi('/v1/customs/consignment-vehicle-identification');

    return {
      data: data ?? [],
      mutate,
      error,
      isError: !!error,
      isLoading,
    };
  };

  /**
   * Creates a vehicle identification.
   */
  export async function createVehicleIdentification(
    vehicleIdentification: CreateConsignmentVehicleIdentificationDto,
  ): Promise<ConsignmentVehicleIdentificationDto | undefined> {
    return (
      await api.post<'/v1/customs/consignment-vehicle-identification'>(
        `${backendUri}/v1/customs/consignment-vehicle-identification`,
        vehicleIdentification,
      )
    ).data;
  }

  /**
   * Deletes vehicle identification by its Id.
   */
  export async function deleteVehicleIdentificationById(
    vehicleIdentificationId: number,
  ): Promise<IVehicleIdentification | undefined> {
    return (
      await client.delete<IVehicleIdentification>(
        `${backendUri}/v1/customs/consignment-vehicle-identification/${vehicleIdentificationId}`,
      )
    ).data;
  }

  export const usePersonsInCharge = (): IUseArrayResponse<ConsignmentPersonInChargeDto> => {
    const { data, mutate, error, isLoading } = useApi('/v1/customs/consignment-person-in-charge');

    return {
      data: data ?? [],
      mutate,
      error,
      isError: !!error,
      isLoading,
    };
  };

  /**
   * Creates a person in charge.
   */
  export async function createPersonInCharge(
    personInChargeDto: ICreatePersonInChargeDto,
  ): Promise<IPersonInCharge | undefined> {
    return (
      await client.post<ICreatePersonInChargeDto, AxiosResponse<IPersonInCharge>>(
        `${backendUri}/v1/customs/consignment-person-in-charge`,
        personInChargeDto,
      )
    ).data;
  }

  /**
   * Returns person in charge by its Id.
   */
  export async function deletePersonInChargeById(personInChargeId: number): Promise<IPersonInCharge | undefined> {
    return (
      await client.delete<IPersonInCharge>(`${backendUri}/v1/customs/consignment-person-in-charge/${personInChargeId}`)
    ).data;
  }

  export const useConsignmentParties = (options?: {
    isCarrier?: boolean;
    isTransitConsignor?: boolean;
    isShippingPoint?: boolean;
  }): IUseArrayResponse<ConsignmentPartyDto> => {
    const { data, mutate, error, isLoading } = useApi('/v1/customs/consignment-party', options);

    return {
      data: data ?? [],
      mutate,
      error,
      isError: !!error,
      isLoading,
    };
  };

  /**
   * Creates a carrier for consignment.
   */
  export async function createConsignmentParty(
    personInChargeDto: Omit<ConsignmentPartyDto, 'consignmentPartyId' | 'createdAt' | 'updatedAt'>,
  ): Promise<IConsignmentParty | undefined> {
    return (
      await client.post<ICreateConsignmentCarrier, AxiosResponse<IConsignmentParty>>(
        `${backendUri}/v1/customs/consignment-party`,
        personInChargeDto,
      )
    ).data;
  }

  /**
   * Delets carrier for consignment by its Id.
   */
  export async function deleteConsignmentPartyById(consignmentPartyId: number): Promise<IConsignmentParty | undefined> {
    return (await client.delete<IConsignmentParty>(`${backendUri}/v1/customs/consignment-party/${consignmentPartyId}`))
      .data;
  }

  export const useGoodsDescription = () => {
    const { data, isLoading, mutate } = useSWR<string[]>(`${backendUri}/v1/customs/goods-description`);

    return { data: data ?? [], isLoading, mutate };
  };

  export const updateGoodsDescription = async (descriptions: string[]) => {
    await api.put<'/v1/customs/goods-description'>(`${backendUri}/v1/customs/goods-description`, { descriptions });
  };

  export const useDutiesAndVat = (options: {
    processIds: number[];
    fromDate?: string | null;
    toDate?: string | null;
    identifier?: string | null;
    limit?: number;
    offset?: number;
  }): IUseObjectResponse<ShipmentDutiesAndVatResponseDto> => {
    const isOptionsValid =
      !!options.processIds.length && (!!options.identifier || (!!options.fromDate && !!options.toDate));

    const { data, error, mutate, isLoading } = useApi(isOptionsValid ? `/v1/customs/duties-and-vat` : null, options);

    return {
      data: data ?? null,
      error,
      isLoading,
      isError: !!error,
      mutate,
    };
  };

  export const getDutiesAndVat = async (options: {
    processIds: number[];
    fromDate?: string;
    toDate?: string;
    identifier?: string;
    limit?: number;
    offset?: number;
  }) => {
    const { data } = await api.get(`/v1/customs/duties-and-vat`, { params: options });

    return data as ShipmentDutiesAndVatResponseDto;
  };
}
